.branding-title {
	display: inline-block;
	margin: 0;
	@include font-size(46);
	font-weight: normal;
	line-height: 1em;
}

.branding-logo {
	display: inline-block;
	margin: 0 auto;
	@include font-size(36);
	font-family: $font-family-headings;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	color: theme(primary, base) !important;
	small {
		display: block;
		padding: 6px 20px 5px;
		@include font-size(17);
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-transform: uppercase;
		letter-spacing: 1px;
		border-radius: 100px;
		background-color: theme(secondary, base);
		color: $white;
	}
	@include media-breakpoint-up(sm) {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		small { margin-left: 10px; }
	}
}

	.branding-logo {}

	.site-info-logo {}